import React from 'react';
class Menu extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.props = props;
	}
	
	render() {
		return (
			<div className="menu">
				<div className="menu-container">
					<div className="menu-item"><a href={`/page/${this.props.pages.first}`}>First Comic</a></div>
					<span>-</span>
					<div className="menu-item"><a href={`/page/${this.props.pages.recent}`}>Latest Comic</a></div>
					<span>-</span>
					<div className="menu-item"><a href="/archive/">Archive</a></div>
					<span>-</span>
					<div className="menu-item"><a href="/about/">About</a></div>
					<span>-</span>
					<div className="menu-item menu-item--orange"><a href="https://www.patreon.com/anfh" rel="noopener noreferrer" target="_blank">Patreon</a></div>
				</div>
			</div>
		);
	}
}

export default Menu;