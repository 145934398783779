import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from 'axios';
import Menu from './components/Menu';
import Loading from './components/Loading';
import imgHeader from './images/header.png';
import './style.scss';

const Comic = lazy(() => import('./pages/Comic')),
	Home = lazy(() => import('./pages/Home')),
	About = lazy(() => import('./pages/About')),
	Archive = lazy(() => import('./pages/Archive'));

class App extends React.Component {
	constructor(props, context) {
		super(props, context);
		this.state = {};
		this.getNavData();
	}
	getNavData() {
		axios.get('https://api.anfh.szar.dev/?site_nav=true').then((resp)=>{
			this.setState({
				pages: {
					first: resp.data.first,
					recent: resp.data.recent,
					next: resp.data.next,
					prev: resp.data.prev
				}
			})
				
		}).catch(function (error) {
			console.log(error.response);
		});

	}
	render() {
		return (
				<BrowserRouter>
					<Helmet>
						<meta name="robots" content="noindex,follow" />
						<title>A NOCTURNE FOR HER - Comics - Page 1.20</title>
						<meta name="description" content="In a world where the gods are a real and observable phenomenon, one idiot unknowingly stumbles across arguably the most powerful object to ever exist. Endowed with its divine might, he uses it to rummage around in your trash without getting caught. Yes, yours. He's doing it right now. You'll never know, though. Join me on a magical adventure in which I struggle to make a comic while having absolutely no idea what I'm doing. Together we will explore the RPG-inspired, cliché-fantasy land of Anithir, all while watching this utter asshole make an absolute fool of himself and possibly accidentally get tangled up in a plot for world domination or something. (You might want to be at least 15 years of age to read this, as there may be partial nudity, violence, profanity, and some content slightly suggestive in nature. However, there is no explicit content! Hooray!)" />
						<link href="https://fonts.googleapis.com/css?family=Roboto:400,700,900|Source+Code+Pro:400,700,900" rel="stylesheet" />
					</Helmet>
					{typeof this.state.pages==='undefined'?null:
					<div id="anfh">
						<div className="container">
							<div className="main">
								<div className="header"><a href="/"><img src={imgHeader} alt="A Nocturne for Her" /></a></div>
								<Menu pages={this.state.pages} />
								<Suspense fallback={<Loading />}>
									<Route exact path="/" render ={()=> <Comic page={this.state.pages.first} pages={this.state.pages}/>} />
									<Route path="/page/:page" render={(props) => <Comic {...props} pages={this.state.pages} />}/>
									<Route path="/about" render={(props) => <About {...props} pages={this.state.pages} />}/>
									<Route path="/archive" render={(props) => <Archive {...props} pages={this.state.pages} />}/>
								</Suspense>
							
								<div className="footer">
									<div className="row">
										<div className="col">© <span className="date-current">{new Date().getFullYear()}</span> N. Ellis</div>
										<div className="col"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					}
					
				</BrowserRouter>
			
		)
	}
}

export default App;
